@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./assets/fonts/Poppins-Regular.ttf) format('ttf');
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}


.loading {
  position: absolute;
  top: 40%;
  left: 40%;
}

.loading img {
  width: 20%;
  height: auto;
}

.bg-image {
  background: url('./assets/img/bg.jpg');
  background-repeat: no-repeat;
  background-size: contain;
}

.zero-margin {
  margin: 0px !important;
  padding: 0px !important;
}

.module {
  padding: 0px;
}

.module,
.module-group {
  display: inline-block;
}

.module.left {
  float: left;
  margin-right: 20px;
  padding-top: 7px;
  padding-bottom: 7px;
}

.module.left .sub {
  font-family: "Poppins", sans-serif;
}

.module.right {
  float: right;
  padding-top: 0px;
}

.list-inline>li {
  display: inline-block;
  padding-right: 5px;
  padding-left: 5px;
}

.top-menu-bar {
  /* background-color: darkblue; */
}

.topbar-bg {
  /* background-color: rgb(53, 3, 89); */
  background-color: rgb(2, 48, 88);
  color: aliceblue !important;
}

ul {
  padding-left: 0px !important;
}

body {
  overflow-x: hidden;
  font-family: "Poppins", sans-serif;
}

.best-features-area {
  background: rgba(91, 74, 249, 0.1) !important;
}

.module.right ul {
  margin-bottom: 0px;
}

.module.right li a {
  font-size: 22px;
  padding: 5px 10px;
  color: aliceblue;
}

.module.right li a:hover {
  color: rgb(116, 6, 111);
}

#navigation li a {
  text-decoration: none;
  font-family: "Poppins", sans-serif;
}

.services-area {
  padding-top: 70px !important;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}

p.blogs-content {
  height: 100px;
  line-height: 20px;
  overflow: hidden;
}

.post_item img {
  width: 80px;
  height: 80px;
}

.learning-icons {
  width: 45px;
}

.service-cap p {
  text-decoration: none;
}









.container-1280px.round-container {
  flex-direction: row;
  align-items: center;
  padding-top: 60px;
  padding-bottom: 0;
}

.container-1280px {
  width: 100%;
  max-width: 1280px;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  margin-left: auto;
  margin-right: auto;
  padding-top: 100px;
  padding-bottom: 100px;
  display: flex;
  position: relative;
}

.round-blocks-contain {
  width: 55%;
  max-width: 720px;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  display: flex;
}

.w-layout-grid {
  grid-row-gap: 16px;
  grid-column-gap: 16px;
  grid-template-rows: auto auto;
  grid-template-columns: 1fr 1fr;
  grid-auto-columns: 1fr;
  display: grid;
}

.round-column-grid {
  width: 50%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  grid-template-columns: 1fr;
}

.round-block-wapper._1 {
  background-color: #ffece9;
}

.round-block-wapper._2 {
  background-color: #ece2fa;
}

.round-block-wapper._3 {
  background-color: #e2e7ff;
}

.round-block-wapper._4 {
  background-color: #ffe3ef;
}

.round-block-wapper {
  width: 100%;
  grid-column-gap: 12px;
  grid-row-gap: 12px;
  border-radius: 16px;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;
  display: flex;
}

.round-block-icon {
  width: 100%;
  max-width: 48px;
  margin-bottom: 32px;
}

.round-block-icon.extra {
  margin-bottom: 94px;
}

.paragraph-22px.round-subheading-text {
  opacity: .9;
  margin-top: auto;
  font-weight: 600;
  line-height: 128%;
}

.paragraph-22px {
  font-size: 22px;
}

.paragraph-22px.round-point-text {
  opacity: .9;
  font-weight: 500;
  line-height: 128%;
}

.colored-button-border-layer {
  width: 100%;
  height: 100%;
  background-color: #3e3e3e;
  background-image: linear-gradient(#3e3e3e, #b5b5ce 57%, #cea1e2);
  border-radius: 50%;
  display: none;
  position: absolute;
  top: 50%;
  bottom: 0%;
  left: 0%;
  right: 0%;
  transform: translateY(-50%);
}

.button-animate {
  padding: 10px 40px;
  border-radius: 20px;
  font-weight: bold;
  font-size: 20px;
  text-decoration: none !important;
  color: #080bb3;
  background-color: #cea1e2;
  /* background-image: linear-gradient(135deg, #986eeb, #fd68b3 11.11%, #fdb786 22.22%, #fdb786 33.33%, #fdb786 44.44%, #986eeb 55.55%, #fd68b3 66.66%, #fdb786 77.77%, #fdb786 88.88%, #fdb786);
  transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
  transform-style: preserve-3d; */
}

.round-text-wrapper {
  width: 40%;
  max-width: 480px;
  flex-direction: column;
  align-items: flex-start;
  display: flex;
}

.heading-48px.round-heading {
  margin-bottom: 20px;
}

.round-check-list-container {
  width: 100%;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 40px;
  display: flex;
}

.round-check-list-row {
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: flex-start;
  display: flex;
}

body {
  background-color: #ffffff !important;
}

.bg-curve {
  background-image: url('./assets/img/home/bg-curve-shape.svg');
  background-repeat: no-repeat;
  background-size: contain;
  padding-bottom: 100px;
}

.our-technologies .tech-div, .our-clients .client-div {
  background-image: linear-gradient(to right, #f0f5f7, #f5f9ff);
  border-radius: 10px;
  box-shadow: 0px 5px 5px #dddddd;
  height: 160px;
  margin-bottom: 20px;
}

.tech-div img, .client-div img {
  width: 80%;
  height: 100px;
  margin: 10px 10%;
}

.tech-div p, .client-div p {
  text-align: center;
  margin-top: 10px;
  font-weight: bold;
}

.footer-main {
  /* background-color: rgba(1, 24, 44, 1); */
  background-color: rgb(2, 48, 88);
}

.bg-home {
  background-color: rgb(96, 173, 240);
  background-image: url(./assets/img/home/home-best-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
}

.bg-home2 {
  background-color: rgb(226, 244, 248);
  background-image: url(./assets/img/home/techbg.png);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
}

.bg-home3 {
  background-color: rgb(226, 244, 248);
  background-image: url(./assets/img/home/section_bg_2.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
}

.bg-home4 {
  /* background-color: rgb(226, 244, 248);
  background-repeat: no-repeat;
  background-size: cover; */
  padding: 100px 0px;
}

.bg-home5 {
  background-color: rgb(226, 244, 248);
  background-image: url(./assets/img/structure-left-vector.webp);
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0px;
}

.button-blue {
  font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    padding: 18px 40px;
    display: inline-flex;
    align-items: center;
    position: relative;
    transition: all .35s;
    border: none;
    background: #105af2;
    color: #fff;
    text-decoration: none;
    transition: .4s;
    border-radius: 30px;
}

.hb-bold {
  font-size: 50px;
}

.hb-semibold {
  font-size: 30px;
}

.main-header .main-menu ul li>a::before {
  /* background-color: rgb(2, 48, 88) !important; */
  background: #105af2 !important;
}

.home-main-div {
  background-color: #cea1e2;
  box-shadow: 0px 5px 5px #dddddd;
}

.main-header .main-menu ul li>a {
  color: rgb(2, 48, 88) !important;
}

.footer-area .footer-tittle h4 {
  color: aliceblue !important;
}

.footer-area p, .footer-area a {
  color: aliceblue !important;
}

.hb-border-top {
  border-top: 1px solid rgba(100, 100, 100, 0.5);
}

.blog-info-link span {
  border: 1px solid #eeeeee;
  background-color: #f2f7ff;
  border-radius: 10px;
  box-shadow: 1px 1px 1px #dddddd;
  padding: 5px 10px;
  clear: both;
}


/* Rahul Rana - Landing Page CSS */
.boxes-area {
  margin-top: 15px;
  padding-bottom: 80px;
  position: relative;
  z-index: 1;
}
.justify-content-center {
  justify-content: center !important;
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: calc(var(--bs-gutter-x) * -0.5);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-top: calc(var(--bs-gutter-y) * -1);
}
.col-sm-6 {
  flex: 0 0 auto;
  width: 50%;
}
.single-box {
  margin-bottom: 30px;
}
.single-box {
  background: #fff;
  border: 1px dashed #cdf1d8;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  padding: 30px 25px;
  position: relative;
  transition: 0.5s;
  z-index: 1;
}
.single-box:before {
  background: linear-gradient(135deg, #23bdb8, #43e794);
  border-radius: 5px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.5s;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}
.single-box .icon {
  background: #cdf1d8;
  border-radius: 50%;
  color: #44ce6f;
  display: inline-block;
  height: 65px;
  line-height: 55px;
  text-align: center;
  transition: 0.5s;
  width: 65px;
}
img, svg {
  vertical-align: middle;
}
.flaticon-plane{
  vertical-align: middle; 
}
.icon .flaticon-plane{
  height: 105px;
  width: 60px;
  display: block;
}

.single-box h3 {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 15px;
  margin-top: 25px;
  transition: 0.5s;
}
.single-box bg-c679e3 .icon .flaticon-plane a{
  font-size: 4em ;
}
.single-box h3 a:hover {
  text-decoration: underline;
}
.single-box a, a:focus, a:hover {
  text-decoration: none;
}

a {
  display: inline-block;
    outline: 0 !important;
}
p:last-child {
  margin-bottom: 0;
}
p {
  color: #6084a4;
  line-height: 1.8;
}

.single-box p {
  transition: 0.5s;
}
p {
  margin-bottom: 1rem;
  margin-top: 0;
}
.single-box.bg-f78acb {
  border-color: rgba(247, 138, 203, 0.3);
}
.single-box.bg-f78acb .icon {
  background: rgba(247, 138, 203, 0.3);
  color: #f78acb;
}
.single-box.bg-c679e3 {
  border-color: #edc3fc;
}
.single-box.bg-c679e3 .icon {
  background: #edc3fc;
  color: #fa7f11;
}
.single-box:hover {
  border-color: transparent;
  -webkit-transform: translateY(-9px);
  transform: translateY(-9px);
}
.single-box:hover:before {
  opacity: 1;
  visibility: visible;
}
.single-box:hover .icon {
  background: #fff;
  color: #44ce6f;
}
.single-box:hover a,
.single-box:hover h3,
.single-box:hover p {
  color: #fff;
}
.single-box:hover.bg-f78acb .icon {
  color: #f78acb;
}
.single-box:hover.bg-c679e3 .icon {
  color: #fa7f11;
}

/* Rahul Rana - Landing Page CSS Ends here */

/* Services cotennt */
.services-bg{
  background-color: #f7fafd;
}
.services-area {
  overflow: hidden;
}
.services-content .section-title {
  margin-top: 54px;
  margin-bottom: 20px;
  text-align: left;
}
.services-content .section-title p {
  margin-top: 1px;
}
.services-content .row {
  margin-left: -8px;
  margin-right: -8px;
  margin-bottom: 20px;
}
.services-content .box {
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
  color: #6084a4;
  display: block;
  font-weight: 500;
  margin-top: 20px;
  padding: 15px 12px 15px 45px;
  position: relative;
  transition: 0.5s;
  z-index: 1;
}
.services-content .box:before {
  background: linear-gradient(135deg, #23bdb8, #43e794);
  border-radius: 3px;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: 0.5s;
  visibility: hidden;
  width: 100%;
  z-index: -1;
}
.services-content .box svg {
  color: #44ce6f;
  display: inline-block;
  left: 15px;
  margin-right: 5px;
  position: absolute;
  top: 12px;
  transition: 0.5s;
  width: 18px;
}
.services-content .box:hover {
  color: #fff;
  -webkit-transform: translateY(-5px);
  transform: translateY(-5px);
}
.services-content .box:hover:before {
  opacity: 1;
  visibility: visible;
}
.services-content .box:hover svg {
  color: #fff;
}
.row .box a, a:focus, a:hover {
  text-decoration: none;
  color: #0e314c;
}
.services-left-image img {
    display: block;
    position: relative;
    left: 0;
    margin: 0 auto;
    right: 0;
}
.contact{
  font-weight: bold;
  padding-top :12px;
  padding-bottom: 12px;
} 
.contact .contact-content {
  padding-left: 100px; 
}
.contact .contact-content p {
  color: white;
}

 .rightBox h2{
  color: #ff7200;
}
.contact-infom {
  display: flex;
  align-items: center;
}
.contact-infom .icon {
  margin-right: 10px;
  margin-bottom: 15px;
}

.companies {
  text-align: center;
}

.companies-title {
  padding-top: 25px;
  font-weight: bold;
}
.companies-title br {
  display: block;
  content: "";
  margin-bottom: -5px; /* Adjust the margin-bottom as needed */
}
.companies-images {
  display: flex;
  justify-content: center;
  align-items: center;
}

.mySwiper {
  width: 80%; 
}

.mySwiper img {
  max-width: 100%;
  max-height: 80px; 
  object-fit: contain;
  margin-left: 200px;
  /* margin: 0 50px;  */
}

.mySwiper img:first-child {
  margin-left: 60px; 
}


.my-trust-swiper{
  width: 90%;
}
.trust-us{
  text-align: center;
}

.trust-us-title{

  padding-top: 25px;
  font-weight: bold;
}


.trust-us-title br{

  display: block;
  content: "";
  margin-bottom: -5px;
}

.card-style{
  width: 18rem;
  margin: 10px auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  overflow: hidden;

  cursor: pointer;
}
.person{
margin: 10px;
}
.person-details{
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 6px;
  padding: 3px;

}
.name{
  font-weight: bold;
  font-size: larger;
}
.role{
  font-weight: 100;
  font-size: large;
}

.card-text, .card-title{
  margin: 10px;
}
.card-image-style{

  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.image-style{
  border-radius: 50%;
  border: #007aff 2px solid;
  object-fit: cover;
  height: 100px;
}
.trust-us-card-title{
  color: #007aff;
  font-size: x-large;
  margin: 10px;

}

.card-link:hover{
  color: #007aff;
}



.my-business-verticals-swiper{
  width: 90%;
}
.business-verticals{
  text-align: center;
}

.business-verticals-title{

  padding-top: 25px;
  font-weight: bold;
}


.business-verticals-title br{

  display: block;
  content: "";
  margin-bottom: -5px;
}


.card-style{
  width: 18rem;
  margin: 10px auto;
  border-radius: 6px;
  box-shadow: 0 0 10px rgba(72, 69, 224, 0.1);
  overflow: hidden;
  cursor: pointer;
}
.person{
margin: 10px;
}
.person-details{
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 6px;
  padding: 3px;

}
.name{
  font-weight: bold;
  font-size: larger;
}
.role{
  font-weight: 100;
  font-size: large;
}
.card-text, .card-title{
  margin: 10px;
}
.business-verticals-card-image-style{
  overflow: hidden;
}
.business-verticals-image-style{
  height: 50%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.business-verticals-image-style:hover{
  transform: scale(1.1);
}
.business-verticals-card-title{
  color: #007aff;
  font-size: x-large;
  margin: 10px;
}

.card-link:hover{
  color: #007aff;
}

.companies-slider-image {
  width: 200px;
  height: auto;
}

.top-menu-bar.container a {
  color: white;
  text-decoration: none;
}
